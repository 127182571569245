import React, { useState, useContext, useEffect } from 'react'
import { CompanyContext } from '../../global_setting/Store';
import config from '../../../src/config.json'
import 'react-dates/initialize';
import 'react-dates/lib/css/_datepicker.css';
import _debounce from 'lodash.debounce'
import "react-datepicker/dist/react-datepicker.css";
import "react-responsive-carousel/lib/styles/carousel.min.css";
import { Carousel } from 'react-responsive-carousel';
// import 'bootstrap/dist/css/bootstrap.min.css';
import VideoSlider from './VideoSlider';

function Slider() {

    const [companyDetails] = useContext(CompanyContext);
    // const [websiteDetails] = useContext(WebsiteDetailsContext);

    const [websiteDetails,setwebsiteDetails] = useState();
    var x = companyDetails && companyDetails.company_id;
    useEffect(() => {
        (async function () {
            try {
                if (companyDetails) {
                    var company_id = companyDetails.company_id
                    var template_id = companyDetails.template_id
                    const staticPageData = await fetch(
                        config.apiEndpoint + '/userwb/get-slider-data/' + company_id + '/' + template_id
                    );
                    const json = await staticPageData.json();
                    const result = json.data;
                   
                    setwebsiteDetails({ "template_identity":result.template_identity,"slider_details":result.slider_details, "selected_benefits" : result.selected_benefits,"slider_default": result && result.slider_default});

                }
            } catch (e) {
                console.error(e);
            }
        })();
    }, [x])
   
    
    return (
        <div>
        {(websiteDetails && websiteDetails.slider_default && websiteDetails.slider_default.default_value ==="video")?
            <div className="c-slider">
                {(websiteDetails === undefined) ? '' : <VideoSlider />}
            </div>
            :
        <div className="c-slider">
            <div className="container-fluid" >
                <Carousel
                    infiniteLoop 
                    autoPlay={true}
                    interval={7000}
                    animateOut='fadeOut'
                >
                    {websiteDetails && websiteDetails.slider_details.map(function (item, i) {
                        return (
                            <div key={i}>
                            <img src={config.wbImagesEndpoint + '/' + item.slider_image} alt={item.slider_image_alt}/>
                            <div className="slider-text">
                                <h3 className="slider-caption slider-caption-1" >{item.slider_caption}</h3>
                            </div>
                        </div>
                        )
                    })}
                </Carousel>
            </div>
        </div>
        }
        </div>
    )
}


export default Slider;