import React, { useState, useEffect, useContext } from 'react';
import { CompanyContext } from '../../../global_setting/Store';
import config from '../../../../src/config.json'

const ContactSection = () => {

    const [companyDetails] = useContext(CompanyContext);
    const [contactSectionData, setContactSectionData] = useState();
    let company_id_val = companyDetails && companyDetails.company_id;
    const [hotel, setHotel] = useState()

    useEffect(() => {
        (async function () {
            try {
                if (company_id_val) {
                    let company_id = companyDetails.company_id
                    let template_id = companyDetails.template_id
                    const contactSection = await fetch(
                        config.apiEndpoint + '/wb-frontview/get-frontview-contact-section-data/' + company_id + '/' + template_id
                    );
                    const result = await contactSection.json();

                    setContactSectionData({ "contact_details": result && result.contact_details, "section_details": result && result.section_details, "all_hotels": result && result.all_hotels_data });
                    setHotel(result && result.all_hotels_data && result.all_hotels_data[0].hotel_id)
                }
            } catch (e) {
                console.error(e);
            }
        })();
    }, [company_id_val])

    let websitefont = '';
    let section_details = contactSectionData && contactSectionData.section_details

    // const websitecolor = websiteDetails && websiteDetails.template_identity[0].theme_color
    const [name, setname] = useState('');
    const [validname, setvalidname] = useState(false);
    const [requiredname, setrequiredname] = useState(false);
    const [email, setemail] = useState('');
    const [validemail, setvalidemail] = useState(false);
    const [requiredemail, setrequiredemail] = useState(false);
    const [number, setnumber] = useState('');
    const [validnumber, setvalidnumber] = useState(false);
    const [requirednumber, setrequirednumber] = useState(false);
    const [message, setmessage] = useState('');
    const [successmsg, setsuccessmsg] = useState(false);
    // const websitefont = websiteDetails && websiteDetails.template_identity[0].theme_font
    // let contactDetails = websiteDetails && websiteDetails.contact_details

    const setHotelID = (val) => {
        setHotel(val);
    }

    const contactform = () => {

        if (name.length === 0) {
            setrequiredname(true)
        }
        else {
            setrequiredname(false)
        }
        if (email.length === 0) {
            setrequiredemail(true)
        }
        else {
            setrequiredemail(false)
        }
        if (number.length === 0) {
            setrequirednumber(true)
        }
        else {
            setrequirednumber(false)
        }
        if (name.length !== 0 && email.length !== 0 && number.length !== 0) {
            setrequiredname(false)
            setrequiredemail(false)
            setrequirednumber(false)
            fetch(config.apiEndpoint + '/userwb/send-user-query',
                {
                    method: 'POST',
                    headers: {
                        Accept: 'application/json',
                        'Content-Type': 'application/json',
                    },
                    body: JSON.stringify({
                        "hotel_id": hotel,
                        "name": name,
                        "email": email,
                        "mobile": number,
                        "message": message
                    })
                })
                .then((response) => response.json())
                .then((responseJson) => {
                    setsuccessmsg(true)
                })

            fetch(config.apiEndpoint + '/insert-customer-data',
                {
                    method: 'POST',
                    headers: {
                        Accept: 'application/json',
                        'Content-Type': 'application/json',
                    },
                    body: JSON.stringify({
                        "hotel_id": hotel,
                        "user_name": name,
                        "user_email_id": email,
                        "user_contact_number": number,
                        "user_message": message
                    })
                })
                .then((response) => response.json())
                .then((responseJson) => {
                    setsuccessmsg(true)
                })
        }
    }
    const validateName = (name) => {
        setname(name)
        if (name.match(/[A-Za-z]{2,}/)) {
            setvalidname(false)
        }
        else {
            setvalidname(true)
        }


    }
    const validateEmail = (email) => {
        setemail(email)
        if (email.match(/[a-z0-9!#$%&'*+/=?^_`{|}~-]+(?:\.[a-z0-9!#$%&'*+/=?^_`{|}~-]+)*@(?:[a-z0-9](?:[a-z0-9-]*[a-z0-9])?\.)+[a-z0-9](?:[a-z0-9-]*[a-z0-9])?/)) {
            setvalidemail(false)
        }
        else {
            setvalidemail(true)
        }
    }
    const validateNumber = (number) => {
        setnumber(number)
        if (number.match(/^(\+\d{1,2}\s?)?1?\-?\.?\s?\(?\d{3}\)?[\s.-]?\d{3}[\s.-]?\d{4}$/)) {
            setvalidnumber(false)
        }
        else {
            setvalidnumber(true)
        }
    }
    const validateMessage = (message) => {
        setmessage(message)
    }

    return (
        <div>
            <section className="get_contact_area parallax-bg" style={section_details && { backgroundImage: "url(" + config.wbImagesEndpoint + '/' + section_details.background_image + ")" }}>
                <div className="container">
                    <div className="row get_contact_inner">
                        <div className="col-md-6">
                            <div className="left_ex_title">
                                {section_details && <h2 style={{ fontFamily: websitefont }}>{section_details.section_title}</h2>}
                            </div>
                            {section_details && <p dangerouslySetInnerHTML={{ __html: section_details.section_description }}></p>}

                            <form className="contact_us_form row m0" action='' >
                                <div className="form-group col-md-12">
                                    <input type="text" className="form-control" id="name" name="name" placeholder="Name" value={name} onChange={(e) => validateName(e.target.value)} />
                                    <div hidden={validname === false} style={{ color: 'red' }}>Invalid Name</div>
                                    <div hidden={requiredname === false} style={{ color: 'red' }}>Required</div>
                                </div>
                                <div className="form-group col-md-12">
                                    <input type="email" className="form-control" id="email" name="email" placeholder="Email" value={email} onChange={(e) => validateEmail(e.target.value)} />
                                    <div hidden={validemail === false} style={{ color: 'red' }}>Invalid Email</div>
                                    <div hidden={requiredemail === false} style={{ color: 'red' }}>Required</div>

                                </div>
                                <div className="form-group col-md-12">
                                    <input type="text" className="form-control" id="number" name="number" placeholder="Phone no." value={number} onChange={(e) => validateNumber(e.target.value)} />
                                    <div hidden={validnumber === false} style={{ color: 'red' }}>Invalid Number</div>
                                    <div hidden={requirednumber === false} style={{ color: 'red' }}>Required</div>
                                </div>


                                {contactSectionData && contactSectionData.all_hotels && contactSectionData.all_hotels.length > 1 && <div className="form-group col-md-12">
                                    <select className="form-control contact-select-box" value={hotel} onChange={(e) => {
                                        setHotelID(e.target.value)
                                    }}>
                                        {contactSectionData.all_hotels.map(function (item, i) {
                                            return (
                                                <option key={i} value={item.hotel_id}>{item.hotel_name}</option>
                                            )
                                        })}
                                    </select>
                                </div>}



                                <div className="form-group col-md-12">
                                    <textarea className="form-control" name="message" id="message" rows="1" placeholder="Message" value={message} onChange={(e) => validateMessage(e.target.value)}></textarea>
                                </div>
                                <div className="form-group col-md-12">
                                    <div hidden={successmsg === false} style={{ color: 'red', fontSize: 15, fontWeight: 'bold' }}>Submitted Successfully !</div>
                                    <br /><br />
                                    <button type="button" className="submit_btn form-control"
                                        onClick={() => contactform()} style={{ fontFamily: websitefont, color: "#0000000" }}>submit now</button>
                                </div>
                            </form>
                        </div>
                        <div className="col-md-6">
                            <div className="right_contact_info">
                                <div className="contact_info_list">
                                    {contactSectionData && contactSectionData.contact_details && contactSectionData.contact_details.map(function (item, id) {
                                        return (
                                            <div className="media" key={id}>
                                                <div className="media-left">
                                                    {item.icon && <i className={item.icon[0].icon}></i>}
                                                </div>

                                                <div className="media-body">
                                                    <h4 style={{ fontFamily: websitefont }}>{item.address_name}</h4>
                                                    {item.description && <p style={{ fontFamily: websitefont }} dangerouslySetInnerHTML={{ __html: item.description }}></p>}

                                                    {item.mobile_no && <p><i className="fa fa-mobile" aria-hidden="true"></i> Mobile :- <a className="contact-details-link" href={"tel:" + item.mobile_no}>{item.mobile_no}</a></p>}

                                                    {item.landline_no && <p><i className="fa fa-phone" aria-hidden="true"></i> Landline :- <a className="contact-details-link" href={"tel:" + item.landline_no}>{item.landline_no}</a></p>}

                                                    {item.whatsapp_no && <p><i className="fa fa-whatsapp" aria-hidden="true"></i> Whatsapp :- <a className="contact-details-link" href={"https://api.whatsapp.com/send?phone=" + item.whatsapp_no}>{item.whatsapp_no}</a></p>}

                                                    {item.email && item.email.length > 0 && item.email[0] && item.email.map(function (items, idx) {
                                                        return (
                                                            <p key={idx}><i className="fa fa-envelope-o" aria-hidden="true"></i> Email :-<a className="contact-details-link" href={"mailto:" + items}> {items}</a></p>

                                                        )
                                                    })}
                                                </div>
                                            </div>
                                        )
                                    })}
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section>

        </div>
    );
}

export default ContactSection;