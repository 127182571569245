import React, { useState, useEffect, useContext } from 'react';
import { CompanyContext} from '../../global_setting/Store';
import config from '../../../src/config.json'
import { Link } from 'react-router-dom'
import history from '../../history';

function Header() {
    const [companyDetails] = useContext(CompanyContext);
    const [websiteDetails, setwebsiteDetails] = useState();
    var x = companyDetails && companyDetails.company_id;
    useEffect(() => {
        (async function () {
            try {
                if (companyDetails) {
                    var company_id = companyDetails.company_id
                    var template_id = companyDetails.template_id
                    const hotelData = await fetch(
                        config.apiEndpoint + '/userwb/get-header-data/' + company_id + '/' + template_id
                    );
                    const json = await hotelData.json();
                    const result = json.data;

                    if (result && result.header_menu_details.length > 5) {
                        let header_menu = result.header_menu_details;
                        var extra_header_menu = header_menu.splice(6, 6);

                        extra_header_menu.forEach(function (item, i) {
                            item["menu"] = "active";
                        })
                    } else {
                        extra_header_menu = [];
                    }

                    var all_menu_details = {
                        "header": {
                            "header_menus": result && result.header_menu_details,
                            "other_menus": extra_header_menu
                        }
                    }

                    let numbers = [];
                    let emails = [];
                    if (result && result.hotel_details) {
                        numbers = result.hotel_details.original[0].mobile.split(',');
                        emails = result.hotel_details.original[0].email_id.split(',');
                    }

                    setwebsiteDetails({ "menu_details": all_menu_details, "template_identity": result && result.template_identity, "hotel_details": result && result.hotel_details, "hotel_menus": result && result.hotel_menu_details, "phone_numbers": numbers, "emails": emails });

                }
            } catch (e) {
                console.error(e);
            }
        })();
    }, [x])


    const websitefont = websiteDetails && websiteDetails.template_identity[0] && websiteDetails.template_identity[0].theme_font
    const [headerbutton, setHeaderButton] = useState(false);
    const [dropdowntoggle, settoggle] = useState(false)
    let url = window.location.href;
    var splitUrl = url.split('/');
    var activemenu = splitUrl[splitUrl.length - 1]
    var hotelDetails = websiteDetails && websiteDetails.hotel_details.original[0]


    //function use to redirect the page
    const redirectTo = (page) => {
        if (page.page_url === '' || page.page_url === ' ') {
            history.push(config.PATH + page.page);
        } else {
            var page_url = page.page_url
            if (page_url.includes("http://") || page_url.includes("https://")) {
                window.open(page_url)
            }
            else {
                if (page_url.includes("www")) {
                    page_url = page_url.trimStart()
                    window.open("http://" + page_url)
                }

                else {
                    history.push(config.PATH + page_url);
                }
            }
        }
    }

    return (
        <div>

            <header className="main_header_area">
                <div className="top-navbar">
                    <ul className="social-link">

                        {websiteDetails && websiteDetails.emails.length > 0 &&
                            <li className="display-md pull-left"><i className="fa fa-envelope-o"></i>
                                {websiteDetails.emails.map(function (items, i) {
                                    return (
                                        <span key={i}> <a href={"mailto:" + items} style={{ color: "inherit", fontWeight: "700" }} >{items}</a>{i < websiteDetails.emails.length - 1 && <span>,</span>}</span>
                                    )
                                })}
                            </li >}


                        {websiteDetails && websiteDetails.phone_numbers.length > 0 && <li className="display-md pull-left"><i className="fa fa-phone"></i>
                            {websiteDetails.phone_numbers.map(function (items, i) {
                                return (
                                    <span key={i}> <a href={"tel:" + items}>{items}</a>{i < websiteDetails.phone_numbers.length - 1 && <span>,</span>}</span>
                                )
                            })}
                        </li >}


                        <li><a href={(websiteDetails && hotelDetails && hotelDetails.facebook_link) ? hotelDetails.facebook_link : "#"} ><i className="fa fa-facebook"></i></a></li>
                        <li><a href={(websiteDetails && hotelDetails && hotelDetails.instagram_link) ? hotelDetails.instagram_link : "#"} ><i className="fa fa-instagram"></i></a></li>
                        <li><a href={(websiteDetails && hotelDetails && hotelDetails.tripadvisor_link) ? hotelDetails.tripadvisor_link : "#"} ><i className="fa fa-tripadvisor"></i></a></li>
                        <li><a href={(websiteDetails && hotelDetails && hotelDetails.twitter_link) ? hotelDetails.twitter_link : "#"}><i className="fa fa-twitter"></i></a></li>
                    </ul>
                </div>

                <div className="header_menu affix-top">
                    <nav className="navbar navbar-default">
                        <div className="container" >
                            <div className="navbar-header" >
                                <button type="button" className="navbar-toggle " data-toggle="collapse" data-target="#bs-example-navbar-collapse-1" aria-expanded="true" onClick={() => (headerbutton) ? setHeaderButton(false) : setHeaderButton(true)}>
                                    <span className="sr-only">Toggle navigation</span>
                                    <span className="icon-bar" ></span>
                                    <span className="icon-bar"></span>
                                    <span className="icon-bar"></span>
                                </button>

                                {websiteDetails && websiteDetails.template_identity[0] &&
                                    <a className="navbar-brand" onClick={() => history.push(config.PATH + 'home')}>
                                        <img src={config.wbImagesEndpoint + '/' + websiteDetails.template_identity[0].logo} alt={websiteDetails.template_identity[0].logo_alt} />
                                    </a>
                                }
                            </div>
                            <div className={headerbutton ? "navbar-collapse collapse in" : "navbar-collapse collapse"} id="bs-example-navbar-collapse-1" aria-expanded={headerbutton ? "true" : "false"}>
                                <ul className="nav navbar-nav">

                                    {websiteDetails && websiteDetails.menu_details.header.header_menus.map(function (item, i) {
                                        if (item.page_id === 1) {
                                            item.page = item.page_url;
                                        }
                                        if (item.menu_name == "Destinations") {
                                            return (
                                                <li className={(dropdowntoggle) ? "dropdown submenu open" : "dropdown submenu"} key={i}>
                                                    <a className="dropdown-toggle" data-toggle="dropdown" role="button" aria-haspopup="true" aria-expanded={(dropdowntoggle) ? "true" : "false"} onClick={() => (dropdowntoggle) ? settoggle(false) : settoggle(true)} style={{ fontFamily: websitefont }}>
                                                        {item.menu_name}</a>
                                                    <ul className="dropdown-menu">
                                                        {websiteDetails && websiteDetails.hotel_menus.map(function (item, i) {
                                                            var send_hotel_name = item.hotel_name.replace(/[^a-zA-Z ]/g, "");
                                                            send_hotel_name = send_hotel_name.replace(/ +/g, "_");
                                                            send_hotel_name = send_hotel_name.toLowerCase();
                                                            return (
                                                                <li key={i}
                                                                    onClick={() => (headerbutton) ? setHeaderButton(false) : setHeaderButton(true)}>
                                                                    <Link
                                                                        to={{
                                                                            // pathname: config.PATH + 'page',
                                                                            pathname: config.PATH + send_hotel_name,
                                                                            commonpage: item.hotel_id,
                                                                            // search: '?' + (item.hotel_id)
                                                                        }} style={{ fontFamily: websitefont }}
                                                                    >{item.hotel_name}
                                                                    </Link>
                                                                </li>
                                                            )
                                                        })}
                                                    </ul>
                                                </li>
                                            )
                                        }
                                        else {
                                            return (
                                                <li key={i}
                                                    className={(item.page === activemenu ? 'active' : '')} onClick={() => (headerbutton) ? setHeaderButton(false) : setHeaderButton(true)}>
                                                    <a onClick={() => redirectTo(item)}
                                                        style={{ fontFamily: websitefont }}>{item.menu_name}
                                                    </a>
                                                </li>
                                            )
                                        }
                                    })
                                    }
                                    {
                                        (websiteDetails
                                            && websiteDetails.menu_details.header.other_menus.length > 0) ?
                                            <li className={(dropdowntoggle) ? "dropdown submenu open" : "dropdown submenu"}>
                                                <a className="dropdown-toggle" data-toggle="dropdown" role="button" aria-haspopup="true" aria-expanded={(dropdowntoggle) ? "true" : "false"} onClick={() => (dropdowntoggle) ? settoggle(false) : settoggle(true)}>
                                                    <i className="fa fa-bars" aria-hidden="true"></i></a>
                                                <ul className="dropdown-menu">
                                                    {
                                                        websiteDetails.menu_details.header.other_menus.map(function (item, i) {
                                                            if (item.page_id === 1) {
                                                                item.page = item.page_url;
                                                            }
                                                            return <li key={i} onClick={() => (headerbutton) ? setHeaderButton(false) : setHeaderButton(true)}>
                                                                <a onClick={() => redirectTo(item)}
                                                                    style={{ fontFamily: websitefont }}>{item.menu_name}</a>
                                                            </li>
                                                        })
                                                    }
                                                </ul>
                                            </li> : ''
                                    }

                                </ul>

                            </div>
                        </div>
                    </nav>
                </div>
            </header>

        </div>
    )
}

export default Header