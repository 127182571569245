import React, { useState, useEffect, useContext } from 'react';
import { CompanyContext } from '../../../global_setting/Store';
import config from '../../../../src/config.json'
import OwlCarousel from 'react-owl-carousel';
import 'owl.carousel/dist/assets/owl.carousel.css';
import 'owl.carousel/dist/assets/owl.theme.default.css';
import Lightbox from "react-image-lightbox";
import "react-image-lightbox/style.css";
import "react-datepicker/dist/react-datepicker.css";

const GallerySection = () => {

    const [companyDetails] = useContext(CompanyContext);
    const [gallerySectionData, setGallerySectionData] = useState();
    let company_id_val = companyDetails && companyDetails.company_id;

    useEffect(() => {
        (async function () {
            try {
                if (company_id_val) {
                    let company_id = companyDetails.company_id
                    let template_id = companyDetails.template_id
                    const gallerySection = await fetch(
                        config.apiEndpoint + '/wb-frontview/get-frontview-gallery-section-data/' + company_id + '/' + template_id
                    );
                    const result = await gallerySection.json();

                    setGallerySectionData({ "gallery_data": result && result.gallery_data, "section_details": result && result.section_details });

                }
            } catch (e) {
                console.error(e);
            }
        })();
    }, [company_id_val])


    let websitefont = '';
    let section_details = gallerySectionData && gallerySectionData.section_details

    const images = [];

    const [open, isopen] = useState(false)
    const [index, setindex] = useState()
    const Toggle = (name, idx) => {
        isopen(true);
        setindex(idx);
    }
    var allImg = []
    allImg = gallerySectionData && gallerySectionData.gallery_data && gallerySectionData.gallery_data[gallerySectionData.gallery_data.length - 1];

    return (
        <div className="resort_gallery_inner" >
            <div className="container">
                {section_details && <div className="center_title">
                    <h2>{section_details.section_title}</h2>
                    <p dangerouslySetInnerHTML={{ __html: section_details.section_description }}></p>
                </div>}
            </div>
            <div className="parallax-bg" style={section_details && { backgroundImage: "url(" + config.wbImagesEndpoint + '/' + section_details.background_image + ")" }}>
                <div className="image-gallery">
                    {allImg && <div className="resort_gallery">
                        <OwlCarousel
                            className="owl-theme"
                            items={3}
                            autoplay={true}
                            autoplaySpeed={1050}
                            loop
                            nav
                            responsive={{
                                0: {
                                    items: 1
                                },
                                700: {
                                    items: 2
                                },
                                1200: {
                                    items: 3
                                },
                            }}
                        >
                            {allImg.map(function (items, id) {
                                images.push(config.wbImagesEndpoint + "/" + items)
                                return (
                                    <div className="item" key={id}>
                                        <img src={config.wbImagesEndpoint + "/" + items} alt="" />
                                        <div className="resort_g_hover">
                                            <div className="resort_hover_inner">
                                                <a className="light" onClick={() => Toggle(items, id)}><i className="fa fa-expand" aria-hidden="true"></i></a>

                                            </div>
                                        </div>
                                    </div>
                                )
                            })}

                        </OwlCarousel>
                    </div>}
                </div>
            </div>
            {open && (
                <Lightbox
                    className=""
                    mainSrc={images[index]}
                    thumbnailWidth='150px'
                    thumbnailHeight='150px'
                    nextSrc={images[(index + 1) % images.length]}
                    prevSrc={images[(index + images.length - 1) % images.length]}
                    onCloseRequest={() => isopen(false)}
                    onMovePrevRequest={() =>
                        setindex((index + images.length - 1) % images.length)
                    }
                    onMoveNextRequest={() =>
                        setindex((index + 1) % images.length)
                    } />
            )}

        </div>
    )
}

export default GallerySection;
